import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Auth from "../../../helpers/Auth";

export default function Pontuacao() {
  const { flask } = Auth();
  const { id } = useParams();

  const [files, setFiles] = useState({ relatorio: null, gabarito: null });
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(null);

  const handleAlertSuccess = () => {
    Swal.fire({
      icon: "success",
      text: `Arquivos enviados com sucesso!`,
      confirmButtonText: "Ok",
    });
  };

  const handleWrongFile = () => {
    Swal.fire({
      icon: "error",
      text: "Selecione apenas arquivos Excel!",
      confirmButtonColor: "#dc3545",
    });
  };

  const handleApiError = () => {
    Swal.fire({
      icon: "error",
      text: "Algum erro aconteceu com os arquivos enviados! Confirme se está enviando-os corretamente.",
      confirmButtonColor: "#dc3545",
    });
  };

  const handleServerError = () => {
    Swal.fire({
      icon: "error",
      text: "Algum erro com o servidor aconteceu. Tente novamente!",
      confirmButtonColor: "#dc3545",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const type =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (files.relatorio.type !== type || files.gabarito.type !== type)
      return handleWrongFile();
    setLoading(true);
    const data = new FormData();
    data.append("relatorio", files.relatorio);
    data.append("gabarito", files.gabarito);
    data.append("concursoId", id)
    flask
      .post("", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .catch((err) => {
        if(err.request.status===400)
            return handleApiError()
        return handleServerError()
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-2">
          <Col sm>
            <span>Selecione o Relatorio no excel</span>
          </Col>
          <Col sm>
            <span>Selecione o Gabarito no excel</span>
          </Col>
          <Col sm></Col>
        </Row>
        <Row>
          <Col sm>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Control
                type="file"
                onChange={(e) =>
                  setFiles({ ...files, relatorio: e.target.files[0] })
                }
                accept=".xlsx"
              />
            </Form.Group>
          </Col>
          <Col sm>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Control
                type="file"
                onChange={(e) =>
                  setFiles({ ...files, gabarito: e.target.files[0] })
                }
                accept=".xlsx"
              />
            </Form.Group>
          </Col>
          <Col sm>
            <Button style={{ width: "90px" }} type="submit">
              {loading ? <Spinner size="sm" /> : "Enviar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
