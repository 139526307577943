import { Download } from "lucide-react";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Auth from "../../../helpers/Auth";
import { saveAs } from "file-saver";

export default function ExcelButton({
  id,
  cargo,
  status,
  pagamento,
  deleted_at,
}) {
  const { http } = Auth();

  const [spinner, setSpinner] = useState(false);

  // async function baixarArquivoExcel() {
  //   try {
  //     setSpinner(true);
  //     const date = new Date();
  //     const resp = await http.get(
  //       `/listar-inscritos-excel/${props.id}`,
  //       { ...props },
  //       {
  //         responseType: "arraybuffer",
  //       }
  //     );
  //     console.log(resp);
  //     const buffer = resp.data;
  //     const type = resp.headers["content-type"];
  //     const name = `${date.getTime()}.xlsx`;
  //     saveAs(new Blob([buffer]), name, { type });
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       text: "Algum erro com o servidor aconteceu. Tente novamente.",
  //       confirmButtonColor: "#dc3545",
  //     });
  //   } finally {
  //     setSpinner(false);
  //   }
  // }

  const openLinkInNewTab = () => {
    // Concatena o caminho com o valor de process.env
    const fullPath =
      process.env.REACT_APP_BACK_ADMIN +
      `/listar-inscritos-excel/${id}?page=1&qtd=25&status=${
        status || ""
      }&cargo=${cargo || ""}&pagamento=${pagamento || ""}&deleted_at=${
        deleted_at || ""
      }`;
    console.log(fullPath);

    // Abre o link em uma nova guia
    window.open(fullPath, "_blank");
  };

  return (
    <Button>
      {spinner ? (
        <Spinner size="sm" />
      ) : (
        <Download onClick={openLinkInNewTab} />
      )}
    </Button>
  );
}
