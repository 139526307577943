import { Button, Col, Row, Table } from "react-bootstrap";
import Auth from "../../../helpers/Auth";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import React from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

export default function TabelaCandidato({ data }) {
  const { concursos } = data;
  const { logout, http, cddt } = Auth();
  const [showDetalhes, setShowDetalhes] = React.useState(
    Array(concursos?.length).fill(false)
  );

  function handleShowDetalhes(index) {
    const current = [...showDetalhes];
    current[index] = !current[index];
    setShowDetalhes(current);
  }

  function handleDetalhes(item, index) {
    if (!!item.arquivos_s3)
      return (
        <Button variant="ligth" onClick={() => handleShowDetalhes(index)}>
          {showDetalhes[index] ? <ChevronUp /> : <ChevronDown />}
        </Button>
      );
    return <span>-</span>;
  }

  async function handleMostrarIsencao(inscricao, tipo) {
    const index = inscricao.arquivos_s3[tipo].length;
    const name = inscricao.arquivos_s3[tipo][index - 1];
    const submit = {
      inscricao: inscricao.inscricao,
      tipo_arquivo: tipo,
      nome_arquivo: name,
      idUser: inscricao.users_id,
    };
    const resp = await http.post(`baixar-arquivo-s3`, submit, {
      responseType: "blob",
    });
    const buffer = resp.data;
    const type = resp.headers["content-type"];
    saveAs(new Blob([buffer]), name, { type });
  }

  function statusPagamento(inscricao) {
    if (inscricao.isencao === 1) {
      if (inscricao.isencao_status === "Indeferido") {
        if (inscricao.pagamento === 0)
          return <span className="text-danger">Pendente</span>;
        return <span className="text-success">Pago</span>;
      }
      return <span>-</span>;
    }
    if (inscricao.pagamento === 0)
      return <span className="text-danger">Pendente</span>;
    return <span className="text-success">Pago</span>;
  }

  function dataHora(value) {
    // 2023-11-10T15:05:31.000000Z
    const data = new Date(value);
    data.setHours(data.getHours() - 4);
    const dataFormatada = data
      .toISOString()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("-");
    const horario = data.toISOString().slice(11, 16);
    return dataFormatada + " / " + horario;
  }

  function handleNecessidades(items) {
    if (items)
      return items.map((item, index) => {
        if (items.length === index + 1) return <span key={item}>{item}</span>;
        return <span key={item}>{item + ", "}</span>;
      });
  }

  function colunaCartaoConfirmacao(insc) {
    const { isencao_status, pagamento } = insc;
    if (isencao_status === "Deferido" || pagamento === 1)
      return (
        <Link
          className="text-decoration-none"
          onClick={() => handleMostrarIsencao(insc, 'cartao-confirmacao')}
        >
          Visualizar
        </Link>
      );
    return <span>-</span>;
  }

  return (
    <Table responsive hover bordered className="mb-5">
      <thead>
        <tr className="text-center align-middle">
          <th>Inscrição</th>
          <th>Concurso</th>
          <th>Cargo</th>
          <th>Data/Hora da Inscrição</th>
          <th>Status Pagamento</th>
          <th>Tipo Isenção</th>
          <th>Status Isenção</th>
          <th>Cartão Confirmação</th>
          <th>Detalhes</th>
        </tr>
      </thead>
      <tbody>
        {concursos?.length > 0 &&
          concursos.map((item, index) => {
            const necessidades = JSON.parse(item.acessibilidades);
            return (
              <React.Fragment key={item.inscricao}>
                <tr className="text-center align-middle">
                  <td>{String(item.inscricao).padStart(5, "0")}</td>
                  <td>{item.concursoNome}</td>
                  <td>{item.cargoNome}</td>
                  <td>{dataHora(item.created_at)}</td>
                  <td>{statusPagamento(item)}</td>
                  <td>{item.tipo_isencao}</td>
                  <td>{item.isencao_status}</td>
                  <td>{colunaCartaoConfirmacao(item)}</td>
                  <td>{handleDetalhes(item, index)}</td>
                </tr>
                {showDetalhes[index] && item.arquivos_s3 && (
                  <tr>
                    <td></td>
                    <td colSpan="9">
                      <div className="p-2">
                        {item.arquivos_s3.arquivo_isencao && (
                          <Row>
                            <Col xs={4}>
                              <p className="m-0 mb-2">
                                <strong>Isenção:</strong> {item.tipo_isencao}
                              </p>
                            </Col>
                            <Col>
                              <Link
                                className="text-decoration-none"
                                onClick={() =>
                                  handleMostrarIsencao(item, "arquivo_isencao")
                                }
                              >
                                Baixar Documento da Isenção
                              </Link>
                            </Col>
                          </Row>
                        )}
                        {item.arquivos_s3.arquivo_deficiencia && (
                          <Row>
                            <Col xs={4}>
                              <p className="m-0 mb-2">
                                <strong>Deficiência:</strong>{" "}
                                {item.tipo_deficiencia}
                              </p>
                            </Col>
                            <Col>
                              <Link
                                className="text-decoration-none"
                                onClick={() =>
                                  handleMostrarIsencao(
                                    item,
                                    "arquivo_deficiencia"
                                  )
                                }
                              >
                                Baixar Documento da Deficiência
                              </Link>
                            </Col>
                          </Row>
                        )}
                        {item.especial === 1 && necessidades && (
                          <Row>
                            <Col>
                              <strong>Necessidades:</strong>{" "}
                              {handleNecessidades(necessidades)}
                            </Col>
                          </Row>
                        )}
                        {/* recursos_classificacao_preliminar_existe : 0
                            recursos_deficiencia_existe : 0
                            recursos_gabarito_preliminar_existe : 0
                            recursos_isencao_existe : 1 */}
                        {/* {item.recursos_isencao_existe === 1 && ( */}
                        {
                          <Row>
                            <Col xs={4}>
                              <p className="m-0 mb-2">
                                <strong>Recursos:</strong>
                              </p>
                            </Col>
                            <Col>
                              {item.arquivos_s3.recursos_isencao && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_isencao"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Isenção
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3.recursos_deficiencia && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_deficiencia"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Condição de
                                    Deficiência Física
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3
                                .recursos_gabarito_preliminar && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_gabarito_preliminar"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Gabarito
                                    Preliminar
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3
                                .recursos_classificacao_preliminar && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_classificacao_preliminar"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Classificação
                                    Preliminar
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3.recursos_edital_preliminar && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_edital_preliminar"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso Edital
                                    Preliminar de deferimento das incrições
                                  </Link>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        }
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
      </tbody>
    </Table>
  );
}
