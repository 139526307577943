import { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import Swal from "sweetalert2";
import Dropdown from "../../../components/Dropdown";
import { ChevronDown, ChevronUp } from "lucide-react";
import { NumericFormat } from "react-number-format";
import React from "react";
import { saveAs } from "file-saver";

const Inscritos = ({ inscritos, setInscritos }) => {
  const { logout, http, cddt } = Auth();
  const [, setLoading] = useState(false);
  const [showDetalhes, setShowDetalhes] = useState(Array(25).fill(false));
  const [detalhes, setDetalhes] = useState(true);

  function abrirHTMLemNovaAba(htmlPDF) {
    const novaAba = window.open();
    novaAba.document.write(htmlPDF);
    novaAba.document.close();
  }

  const handleGerarPdf = async (inscricao) => {
    const submit = {
      inscricao: inscricao.inscricao,
    };
    setLoading(true);
    await http
      .post("boleto-inscrito", submit)
      .then((resp) => abrirHTMLemNovaAba(resp.data))
      .catch((error) => {
        if (error.response.status === 401) logout();
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            text: error.response.data.Message,
            confirmButtonColor: "#dc3545",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      });
    setLoading(false);
  };

  const [, forceUpdate] = useState(0);
  async function atualizarStatus(status, index) {
    try {
      const values = inscritos;
      const { inscricao } = values[index];
      values[index] = { ...values[index], isencao_status: status };
      await http.post("atualizar-status-isencao", { inscricao, status });
      setInscritos(values);
      forceUpdate(Math.random());
    } catch (erro) {
      console.log(erro);
    }
  }

  function handleStatus(value, index) {
    Swal.fire({
      icon: "warning",
      text: `Deseja alterar o status da isenção para ${value}?`,
      showCancelButton: true,
      confirmButtonText: "Alterar",
      confirmButtonColor: "#0d6efd",
      iconColor: "#dc9900",
      cancelButtonText: "Cancelar",
    }).then((resp) => {
      if (resp.isConfirmed) {
        atualizarStatus(value, index);
      }
    });
  }

  function hundleGerarStatus(inscricao) {
    const { isencao } = inscricao;
    if (isencao === 1) return <span>-</span>;
    return (
      <Link
        className="text-decoration-none"
        onClick={() => handleGerarPdf(inscricao)}
      >
        Gerar
      </Link>
    );
  }

  function handleShowDetalhes(index) {
    const current = [...showDetalhes];
    current[index] = !current[index];
    setShowDetalhes(current);
  }

  function dataHora(value) {
    // 2023-11-10T15:05:31.000000Z
    const data = new Date(value);
    data.setHours(data.getHours() - 4);
    const dataFormatada = data
      .toISOString()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("-");
    const horario = data.toISOString().slice(11, 16);
    return dataFormatada + " / " + horario;
  }

  function statusPagamento(insc, tipo = null) {
    if (insc.isencao === 1) {
      if (insc.isencao_status === "Indeferido") {
        if (insc.pagamento === 0)
          return <span className={tipo ? "" : "text-danger"}>Pendente</span>;
        return <span className={tipo ? "" : "text-success"}>Pago</span>;
      }
      return <span>-</span>;
    }
    if (insc.pagamento === 0)
      return <span className={tipo ? "" : "text-danger"}>Pendente</span>;
    return <span className={tipo ? "" : "text-success"}>Pago</span>;
  }

  function handleNecessidades(items) {
    if (items)
      return items.map((item, index) => {
        if (items.length === index + 1) return <span key={item}>{item}</span>;
        return <span key={item}>{item + ", "}</span>;
      });
  }

  function handleDetalhes(insc, index) {
    if (!!insc.arquivos_s3)
      return (
        <Button variant="ligth" onClick={() => handleShowDetalhes(index)}>
          {showDetalhes[index] ? <ChevronUp /> : <ChevronDown />}
        </Button>
      );
    return <span>-</span>;
  }

  async function handleMostrarIsencao(inscricao, tipo) {
    setLoading(true);
    const index = inscricao.arquivos_s3[tipo].length;
    const name = inscricao.arquivos_s3[tipo][index - 1];
    const submit = {
      inscricao: inscricao.inscricao,
      tipo_arquivo: tipo,
      nome_arquivo: name,
      idUser: inscricao.users_id,
    };
    const resp = await http.post(`baixar-arquivo-s3`, submit, {
      responseType: "blob",
    });
    const buffer = resp.data;
    const type = resp.headers["content-type"];
    saveAs(new Blob([buffer]), name, { type });
    setLoading(false);
  }

  async function handleRecursos(inscricao, tipo) {
    setLoading(true);
    const index = inscricao.arquivos_s3[tipo].length;
    const name = inscricao.arquivos_s3[tipo][index - 1];
    const submit = {
      inscricao: inscricao.inscricao,
      tipo_arquivo: tipo,
      nome_arquivo: name,
      idUser: inscricao.users_id,
    };
    const resp = await http.post(`baixar-arquivo-s3`, submit, {
      responseType: "blob",
    });
    const buffer = resp.data;
    const type = resp.headers["content-type"];
    saveAs(new Blob([buffer]), name, { type });
    setLoading(false);
  }

  function colunaCartaoConfirmacao(insc) {
    const { isencao_status, pagamento } = insc;
    if (isencao_status === "Deferido" || pagamento === 1)
      return (
        <Link
          className="text-decoration-none"
          onClick={() => handleRecursos(insc, 'cartao-confirmacao')}
        >
          Visualizar
        </Link>
      );
    return <span>-</span>;
  }

  return (
    <>
      <Table responsive hover>
        <thead>
          <tr className="text-center align-middle">
            <th>Inscrição</th>
            <th>Usuário</th>
            <th>Cargo</th>
            <th>Valor</th>
            <th>Vencimento</th>
            <th>Status Isenção</th>
            <th>Status Pagamento</th>
            <th>Boleto</th>
            <th>Cartão de Confirmação</th>
            <th
              onClick={() => {
                setShowDetalhes(Array(25).fill(detalhes));
                setDetalhes(!detalhes);
              }}
            >
              <Link className="text-decoration-none">Detalhes</Link>
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "14px" }}>
          {inscritos?.length > 0 &&
            inscritos.map((insc, index) => {
              const necessidades = JSON.parse(insc.acessibilidades);

              if (!insc.deleted_at)
                return (
                  <React.Fragment key={insc.inscricao}>
                    <tr className="text-center align-middle">
                      <td>
                        <p>{String(insc.inscricao).padStart(5, "0")}</p>
                        <span>{dataHora(insc.created_at)}</span>
                      </td>
                      <td>
                        <Link
                          className="text-decoration-none"
                          to={`/usuarios?id=${String(insc.users_id)}`}
                          target="_blank"
                        >
                          {insc.nome_completo.toUpperCase()}
                        </Link>
                      </td>
                      <td>{insc.cargoNome}</td>
                      <td>
                        {
                          <NumericFormat
                            value={insc.valor}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"R$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(formattedValue) => (
                              <p className="m-0">{formattedValue}</p>
                            )}
                          />
                        }
                      </td>
                      <td>
                        {insc.boleto_vencimento.split("-").reverse().join("/")}
                      </td>
                      <td>
                        {insc.isencao === 1 ? (
                          <Dropdown
                            selected={insc.isencao_status}
                            setSelected={handleStatus}
                            options={[
                              "Em análise da comissão",
                              "Deferido",
                              "Indeferido",
                            ]}
                            index={index}
                          />
                        ) : (
                          <span className="">-</span>
                        )}
                      </td>
                      <td>{statusPagamento(insc)}</td>
                      <td>{hundleGerarStatus(insc)}</td>
                      <td>{colunaCartaoConfirmacao(insc)}</td>
                      <td>{handleDetalhes(insc, index)}</td>
                    </tr>
                    {showDetalhes[index] && insc.arquivos_s3 && (
                      <tr>
                        <td></td>
                        <td colSpan="9">
                          <div className="p-2">
                            {insc.arquivos_s3.arquivo_isencao && (
                              <Row>
                                <Col xs={4}>
                                  <p className="m-0 mb-2">
                                    <strong>Isenção:</strong>{" "}
                                    {insc.tipo_isencao}
                                  </p>
                                </Col>
                                <Col>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        insc,
                                        "arquivo_isencao"
                                      )
                                    }
                                  >
                                    Baixar Documento da Isenção
                                  </Link>
                                </Col>
                              </Row>
                            )}
                            {insc.arquivos_s3.arquivo_deficiencia && (
                              <Row>
                                <Col xs={4}>
                                  <p className="m-0 mb-2">
                                    <strong>Deficiência:</strong>{" "}
                                    {insc.tipo_deficiencia}
                                  </p>
                                </Col>
                                <Col>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        insc,
                                        "arquivo_deficiencia"
                                      )
                                    }
                                  >
                                    Baixar Documento da Deficiência
                                  </Link>
                                </Col>
                              </Row>
                            )}
                            {insc.especial === 1 && necessidades && (
                              <Row>
                                <Col>
                                  <strong>Necessidades:</strong>{" "}
                                  {handleNecessidades(necessidades)}
                                </Col>
                              </Row>
                            )}
                            {/* recursos_classificacao_preliminar_existe : 0
                            recursos_deficiencia_existe : 0
                            recursos_gabarito_preliminar_existe : 0
                            recursos_isencao_existe : 1 */}
                            {/* {insc.recursos_isencao_existe === 1 && ( */}
                            {
                              <Row>
                                <Col xs={4}>
                                  <p className="m-0 mb-2">
                                    <strong>Recursos:</strong>
                                  </p>
                                </Col>
                                <Col>
                                  {insc.arquivos_s3.recursos_isencao && (
                                    <Row>
                                      <Link
                                        className="text-decoration-none"
                                        onClick={() =>
                                          handleRecursos(
                                            insc,
                                            "recursos_isencao"
                                          )
                                        }
                                      >
                                        Baixar Documento de Recurso de Isenção
                                      </Link>
                                    </Row>
                                  )}
                                  {insc.arquivos_s3.recursos_deficiencia && (
                                    <Row>
                                      <Link
                                        className="text-decoration-none"
                                        onClick={() =>
                                          handleRecursos(
                                            insc,
                                            "recursos_deficiencia"
                                          )
                                        }
                                      >
                                        Baixar Documento de Recurso de Condição
                                        de Deficiência Física
                                      </Link>
                                    </Row>
                                  )}
                                  {insc.arquivos_s3
                                    .recursos_gabarito_preliminar && (
                                    <Row>
                                      <Link
                                        className="text-decoration-none"
                                        onClick={() =>
                                          handleRecursos(
                                            insc,
                                            "recursos_gabarito_preliminar"
                                          )
                                        }
                                      >
                                        Baixar Documento de Recurso de Gabarito
                                        Preliminar
                                      </Link>
                                    </Row>
                                  )}
                                  {insc.arquivos_s3
                                    .recursos_classificacao_preliminar && (
                                    <Row>
                                      <Link
                                        className="text-decoration-none"
                                        onClick={() =>
                                          handleRecursos(
                                            insc,
                                            "recursos_classificacao_preliminar"
                                          )
                                        }
                                      >
                                        Baixar Documento de Recurso de
                                        Classificação Preliminar
                                      </Link>
                                    </Row>
                                  )}
                                  {insc.arquivos_s3
                                    .recursos_edital_preliminar && (
                                    <Row>
                                      <Link
                                        className="text-decoration-none"
                                        onClick={() =>
                                          handleRecursos(
                                            insc,
                                            "recursos_edital_preliminar"
                                          )
                                        }
                                      >
                                        Baixar Documento de Recurso Edital
                                        Preliminar de deferimento das incrições
                                      </Link>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            }
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              return (
                <React.Fragment key={insc.inscricao}>
                  <tr className="text-center align-middle text-black-50">
                    <td>{String(insc.inscricao).padStart(5, "0")}</td>
                    <td>
                      <Link
                        className="text-decoration-none text-dark"
                        to={`/usuarios?id=${String(insc.users_id)}`}
                        target="_blank"
                      >
                        {insc.nome_completo.toUpperCase()}
                      </Link>
                    </td>
                    <td>{insc.cargoNome}</td>
                    <td>
                      {
                        <NumericFormat
                          value={insc.valor}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"R$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(formattedValue) => (
                            <p className="m-0">{formattedValue}</p>
                          )}
                        />
                      }
                    </td>
                    {/* <td>{dataHora(insc.created_at)}</td> */}
                    <td>
                      {insc.boleto_vencimento.split("-").reverse().join("/")}
                    </td>
                    <td>{insc.isencao_status}</td>
                    {/* <td>
                        {insc.isencao === 1 ? (
                          <Dropdown
                            selected={insc.isencao_status}
                            setSelected={handleStatus}
                            options={[
                              "Em análise da comissão",
                              "Deferido",
                              "Indeferido",
                            ]}
                            index={index}
                          />
                        ) : (
                          <span className="">-</span>
                        )}
                      </td> */}
                    <td>{statusPagamento(insc, true)}</td>
                    <td>-</td>
                    <td>{colunaCartaoConfirmacao(insc)}</td>
                    <td>{handleDetalhes(insc, index)}</td>
                  </tr>
                  {showDetalhes[index] &&
                    (insc.isencao === 1 ||
                      insc.especial === 1 ||
                      insc.deficiencia === 1) && (
                      <tr className="text-black-50">
                        <td></td>
                        <td colSpan="9">
                          <div className="p-2">
                            {insc.isencao === 1 && (
                              <Row>
                                <Col xs={4}>
                                  <p className="m-0 mb-2">
                                    <strong>Isenção:</strong>{" "}
                                    {insc.tipo_isencao}
                                  </p>
                                </Col>
                                <Col>Baixar Documento da Isenção</Col>
                              </Row>
                            )}

                            {insc.deficiencia === 1 && (
                              <Row>
                                <Col xs={4}>
                                  <p className="m-0 mb-2">
                                    <strong>Deficiência:</strong>{" "}
                                    {insc.tipo_deficiencia}
                                  </p>
                                </Col>
                                <Col>Baixar Documento da Deficiência</Col>
                              </Row>
                            )}
                            {insc.especial === 1 && necessidades && (
                              <Row>
                                <Col>
                                  <strong>Necessidades:</strong>{" "}
                                  {handleNecessidades(necessidades)}
                                </Col>
                              </Row>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                </React.Fragment>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default Inscritos;
