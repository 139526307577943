import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Auth from "../../helpers/Auth";
import UserCard from "./Components/Usuarios";
import Loading from "../../components/Loading";
import TabelaCandidato from "./Components/Tabela";
import { Search } from "lucide-react";
import { useLocation } from "react-router-dom";

const Usuarios = () => {
  const { http, logout } = Auth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();

  const [cpf, setCpf] = useState({
    cpf: "",
  });

  const [user, setUser] = useState(null);

  const hundleCpf = (e) => {
    setCpf({ ...cpf, cpf: e.target.value });
  };

  const hundleSubmit = (e = null, params = null) => {
    try {
      if (e) e.preventDefault();
      setLoading(true);
      http
        .post(
          "/buscar/usuarios",
          params || {
            cpf: cpf.cpf.includes("@") ? cpf.cpf : cpf.cpf.replace(/\D/g, ""),
          }
        )
        .then((res) => {
          setUser(res.data.usuario);
          setMessage("");
          setLoading(false);
        });
    } catch (error) {
      const { statusText } = error.response;
      switch (statusText) {
        case "Not Found":
          setUser(null);
          setMessage("Candidato não encontrado");
          break;
        case "Unauthorized":
          logout();
          break;
        case "Unauthenticated":
          logout();
          break;
        default:
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get("id");
      if (id) {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("users_id", id);
        const resp = await http.post("buscar-cpf", formdata);
        setCpf({ cpf: String(resp.data) });
        hundleSubmit(null, { cpf: String(resp.data) });
      }
    })();
  }, []);

  return (
    <Container>
      {loading && (
        <div
          className="bg-white bg-opacity-75 position-absolute top-50 start-50 translate-middle w-100"
          style={{ zIndex: "1000" }}
        >
          <Loading />
        </div>
      )}

      <div className="d-flex flex-column justify-content-center align-items-center mt-5">
        <div className="mb-4 fs-5">
          Digite o CPF ou a inscrição de algum candidato para buscar suas
          informações
        </div>
        <Form onSubmit={hundleSubmit}>
          <Row>
            <Col className="m-0 p-0" xs={8}>
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Control
                  // as={IMaskInput}
                  // mask="000.000.000-00"
                  type="text"
                  value={cpf.cpf}
                  style={{ borderRadius: "1rem 0 0 1rem", height: "40px" }}
                  placeholder="CPF ou Inscrição"
                  onChange={hundleCpf}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="m-0 p-0" xs={4}>
              <Button
                type="submit"
                style={{
                  borderRadius: "0 1rem 1rem 0",
                  width: "100%",
                  height: "40px",
                }}
                className="d-flex justify-content-center align-content-center"
              >
                <Search height="30px" />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      {message && <div className="text-danger text-center fs-6">{message}</div>}
      {!message && user && <UserCard data={user} setData={setUser} />}
      {!message && user && <TabelaCandidato data={user} />}
    </Container>
  );
};

export default Usuarios;
